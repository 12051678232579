/* You can add global styles to this file, and also import other style files */
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #f0f0f0;
}

.parent-container {
  //display: none;
  width: 100%;
  position: relative;

  #play-stage {
    &.eraser-mode {
      cursor: url("/assets/eraser.png") -200 500, pointer;
    }
    &.pencil-mode {
      cursor: url("/assets/pencil.png") -500000 500000, pointer;
    }
  }

  .actions {
    bottom: 15px;
    width: 100%;
    position: absolute;
    z-index: 100;
    max-height: 60px;
  }

  .action-options {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: baseline;

    a {
      color: #424242;
      font-family: inherit;
      font-size: 14px;
    }

    .links {
      .ng-fa-icon {
        font-size: 17px;
        line-height: 0;
        margin: 0 5px;
      }
    }

    .footer-logo {
      width: 100%;
      padding: 0;
      margin: -25px;
    }
  }
}

.btn {
  margin-left: 1px;
  border-radius: 0;
  min-width: 40px;
  max-width: 40px;
}


.btn-danger {
  background: #DC3645;
}

.active-shape-img {
  max-height: 35px;
  margin-left: 15px;
}

.links .list-unstyled {
  display: flex;
  align-items: center;
  //width: 600px;
  //justify-content: space-around;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;

  li {
    position: relative;
    margin: 0 10px;
  }

  li:first-child:after, li:nth-child(2):after {
    content: "";
    width: 1px;
    height: 100%;
    background: #757575;
    position: absolute;
    transform: translate(10px, 0px);
  }

}

.globe-color {
  color: #237F6F;
}

.cart-color {
  color: #E69A4B;
}

.mail-color {
  color: #EC5064;
}

// modal
.information-list p {
  margin: 10px 0;
}

.information-list img {
  margin: -5px 10px;
}
.information-list {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

#mat-dialog-0 {
  border-radius: 15px;
}

.close {
  background: red !important;
  width: 23px;
  height: 25px;
  border-radius: 50%;
  color: #fff;
  opacity: 1;
}
.close:hover {
  color: #fff; 
  opacity: .6;
}
// Device = Tablets, Ipads (portrait)
@media (min-width: 650px) and (max-width: 1024px) {
  .system-actions {
    padding: 0 50px;
  }
  .links .list-unstyled li span {
    width: 450px;
    display: none !important;
  }
  .links .list-unstyled li:first-child:after, .links .list-unstyled li:nth-child(2):after {
    content: none;
  }
  .links .list-unstyled {
    width: 30%;
  }
}

textarea {
  resize: none;
}


html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
